



















































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import dayjsUTC from 'dayjs/plugin/utc'
import controller from '@/app/ui/controllers/ProgrammaticVoucherController'
import { EventBus, EventBusConstants, Utils } from '@/app/infrastructures/misc'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import DownloadIcon from '@/app/ui/assets/download_icon.vue'
import { PROGRAMMATIC_VOUCHER_HISTORY_PAGINATION } from '@/app/infrastructures/misc/Constants'
import { NavigationGuardNext, Route } from 'vue-router'
import { ProgrammaticVoucher } from '@/domain/entities/ProgrammaticVoucher'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import { Pagination } from '@/domain/entities/Pagination'
import * as constantData from '@/app/infrastructures/misc/Constants/programaticVoucher'
import { IParameter } from '@/data/infrastructures/misc/interfaces/progamaticVoucher'

dayjs.extend(dayjsUTC)

type fakeDictionary<T> = { [key: string]: T }

@Component({
  components: {
    LoadingOverlay,
    DateTimePicker,
    DataTable,
    DownloadIcon,
    PaginationNav,
    Button,
    EmptyState,
  },
})
export default class ProgrammaticVoucherUsage extends Vue {
  controller = controller
  parameters: IParameter = {
    programmaticVoucherItemId: null,
    page: 1,
    perPage: PROGRAMMATIC_VOUCHER_HISTORY_PAGINATION,
    startDate: null,
    endDate: null,
  }
  isEmptyPurposePointAndExpire = false

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (
      from.query.programmaticVoucherItemId &&
      to.name === 'ProgrammaticVoucherPage' &&
      !to.query.fromSidebar
    ) {
      next({
        name: from.name as string,
        params: {id: from.params.id},
      })
    } else {
      next()
    }
  }

  created(): void {
    const queries = this.$route.query as Record<string, never>
    this.parameters = {
      programmaticVoucherItemId: queries.programmaticVoucherItemId,
      page: Utils.alwaysNumber(queries.page) || 1,
      perPage:
        Utils.alwaysNumber(queries.perPage) ||
        PROGRAMMATIC_VOUCHER_HISTORY_PAGINATION,
      startDate: queries.startDate,
      endDate: queries.endDate,
    }

    controller.getProgrammaticVoucherDetail(this.$route.params.id)
  }

  get voucherDetail(): ProgrammaticVoucher {
    return controller.voucherDetail
  }

  setHeaderNotGroupedByCustomer(): string[] {
    if (this.isDiscountByAmount) {
      return [
        'Program Name',
        'User ID',
        'User Type',
        'Detail User Type',
        'Account Name',
        'Email',
        'City of Residence',
        'Voucher ID',
        'Voucher Type',
        'Voucher Amount',
        'Limit per User',
        'Used Voucher Date',
        'Used Voucher Value',
      ]
    }

    return [
      'Program Name',
      'User ID',
      'User Type',
      'Detail User Type',
      'Account Name',
      'Email',
      'City of Residence',
      'Voucher ID',
      'Voucher Type',
      'Voucher Percentage',
      'Voucher Amount',
      'Limit per User',
      'Used Voucher Date',
      'Used Voucher Value',
    ]
  }

  get headers(): string[] {
    if (this.isVoucherPaymentGroup) {
      if (this.isGroupedByCustomer) {
        let headerNameDiscount = 'Voucher Percentage'
        if (this.isDiscountByAmount) {
          headerNameDiscount = 'Voucher Amount'
        }
        return [
          'Program Name',
          'User ID',
          'Account Name',
          'Email',
          'City of Residence',
          'Voucher Type',
          headerNameDiscount,
          'Action',
        ]
      } else {
        return this.setHeaderNotGroupedByCustomer()
      }
    } else {
      return [
        'Program Name',
        'User ID',
        'User Type',
        'Detail User Type',
        'Account Name',
        'Email',
        'City of Residence',
        'Voucher ID',
        'Voucher Type',
        'Voucher Amount',
        'Get Voucher Date',
        'Used Voucher Date',
        this.isRedeemToParcelPoint
          ? 'Parcel Poin redeemed by user'
          : 'Amount Used',
      ]
    }
  }

  get isDiscountByAmount(): boolean {
    return (
      <constantData.TermConditionType>this.voucherDetail.voucherPurposeChild ===
      constantData.TermConditionType.DISCOUNT_BY_AMOUNT
    )
  }

  get isVoucherPaymentGroup(): boolean {
    return (
      <constantData.PurposeType>this.voucherDetail.voucherPurposeParent ===
      constantData.PurposeType.PAYMENT
    )
  }

  get isTargetSelectedUser(): boolean {
    return (
      <constantData.UserType>this.voucherDetail.voucherTarget ===
      constantData.UserType.SELECTED
    )
  }

  get isRedeemToParcelPoint(): boolean {
    return (
      <constantData.TermConditionType>this.voucherDetail.voucherPurposeChild ===
      constantData.TermConditionType.REDEEM_PARCEL_POINT
    )
  }

  get isGroupedByCustomer(): boolean {
    return (
      !this.$route.query.programmaticVoucherItemId && this.isTargetSelectedUser
    )
  }

  get customerName(): string | undefined {
    return controller.historyData.length
      ? controller.historyData[0].customerName
      : undefined
  }

  get voucherName(): string | undefined {
    return this.voucherDetail.voucherName
  }

  get params(): IParameter {
    return {
      ...this.parameters,
      startDate: this.parameters.startDate
        ? dayjs(this.parameters.startDate, 'YYYY-MM-DD')
        .utc()
        .toISOString()
        : null,
      endDate: this.parameters.endDate
        ? dayjs(this.parameters.endDate, 'YYYY-MM-DD')
        .endOf('days')
        .utc()
        .toISOString()
        : null,
      sortBy: 'NEWEST',
    }
  }

  get paginationData(): Pagination {
    return this.isVoucherPaymentGroup && this.isGroupedByCustomer
      ? controller.paginationDistribution
      : controller.paginationHistory
  }

  setDate(val: string | undefined): string {
    return val ? dayjs(val).format('DD/MM/YYYY') : '-'
  }

  setHistoryDataNotGroupedByCustomer(): Array<Array<unknown>> {
    const data: Array<Array<unknown>> = []
    for (let i = 0; i < controller.historyData.length; i++) {
      const val = controller.historyData[i]
      const limitUsage = val.limitUsage || '-'
      const voucherAmount = `Rp${Utils.currencyDigit(val.voucherAmount || 0)}`
      const voucherUsed = `Rp${Utils.currencyDigit(val.voucherUsed || 0)}`
      const voucherType = constantData.TERM_COND_OPTIONS.find(
        item =>
          <constantData.TermConditionType>val.voucherPurpose === item.value
      )?.label

      if (this.isDiscountByAmount) {
        data.push([
          val.name,
          val.customerId,
          val.userType,
          val.userTypeDetail,
          val.customerName,
          val.customerEmail,
          val.city,
          val.voucherID,
          voucherType,
          voucherAmount,
          limitUsage,
          this.setDate(val.voucherUsedAt),
          voucherUsed,
        ])
      } else {
        data.push([
          val.name,
          val.customerId,
          val.userType,
          val.userTypeDetail,
          val.customerName,
          val.customerEmail,
          val.city,
          val.voucherID,
          voucherType,
          `${val.voucherPercentage}%`,
          `Rp${Utils.currencyDigit(val.voucherMaximumAmount || 0)}`,
          limitUsage,
          this.setDate(val.voucherUsedAt),
          voucherUsed,
        ])
      }
    }
    return data
  }

  get historyData(): Array<Array<unknown>> {
    const data: Array<Array<unknown>> = []
    if (controller.distributionData.length > 0) {
      if (this.isVoucherPaymentGroup) {
        if (this.isGroupedByCustomer) {
          // using FOR better looping performance
          for (let i = 0; i < controller.distributionData.length; i++) {
            let discountValue: string | number = `${controller.distributionData[i].voucherPercentage}%`
            if (this.isDiscountByAmount) {
              discountValue = controller.distributionData[i].voucherAmount || 0
            }

            data.push([
              controller.distributionData[i].name,
              controller.distributionData[i].customerId,
              controller.distributionData[i].customerName,
              controller.distributionData[i].customerEmail,
              controller.distributionData[i].city,
              constantData.TERM_COND_OPTIONS.find(
                item =>
                  <constantData.TermConditionType>(
                    controller.distributionData[i].voucherPurpose
                  ) === item.value
              )?.label,
              discountValue,
              controller.distributionData[i].itemId,
            ])
          }
        } else {
          return this.setHistoryDataNotGroupedByCustomer()
        }
      } else {
        // using FOR better looping performance
        for (let i = 0; i < controller.historyData.length; i++) {
          data.push([
            controller.historyData[i].name,
            controller.historyData[i].customerId,
            controller.historyData[i].userType,
            controller.historyData[i].userTypeDetail,
            controller.historyData[i].customerName,
            controller.historyData[i].customerEmail,
            controller.historyData[i].city,
            controller.historyData[i].voucherID,
            constantData.TERM_COND_OPTIONS.find(
              item =>
                <constantData.TermConditionType>(
                  controller.historyData[i].voucherPurpose
                ) === item.value
            )?.label,
            `Rp${Utils.currencyDigit(
              controller.historyData[i].initialAmount || 0
            )}`,
            this.setDate(controller.historyData[i].voucherCreatedAt),
            this.setDate(controller.historyData[i].voucherUsedAt),
            `Rp${Utils.currencyDigit(
              controller.historyData[i].spentAmount || 0
            )}`,
          ])
        }
      }
    }
    return data
  }

  @Watch('$route', {deep: true})
  onRouteChanged(to: fakeDictionary<IParameter>): void {
    let toQueryId = undefined
    if (to.query.programmaticVoucherItemId) {
      toQueryId = +to.query.programmaticVoucherItemId
    }

    if (
      this.voucherDetail &&
      !this.controller.isLoading &&
      this.parameters.programmaticVoucherItemId !==
      toQueryId
    ) {
      if (to.query.programmaticVoucherItemId) {
        this.parameters.programmaticVoucherItemId = Number(
          to.query.programmaticVoucherItemId
        )
      }
      this.fetchData(true)
    }
  }

  @Watch('voucherDetail')
  onIsVoucherPaymentGroupChanged(): void {
    if (this.voucherDetail.pointPurpose === '' &&
      this.voucherDetail.pointExpiry === -1
    ) {
      this.isEmptyPurposePointAndExpire = true
    }
    this.fetchData(true)
  }

  @Watch('parameters', {deep: true})
  onParametersChanged(val: { endDate: string | undefined, startDate: string | undefined }): void {
    if (
      dayjs(val.endDate || '', 'YYYY-MM-DD').isBefore(
        dayjs(val.startDate || '', 'YYYY-MM-DD')
      ) ||
      !val.endDate
    ) {
      this.parameters.endDate = val.startDate
    }
  }

  // TODO: root caused go back not work
  @Watch('params')
  onParamsChanged(val: fakeDictionary<IParameter>): void {
    this.$router.replace({
      query: {
        ...val,
        startDate: this.parameters.startDate
          ? dayjs(this.parameters.startDate).format('YYYY-MM-DD')
          : null,
        endDate: this.parameters.endDate
          ? dayjs(this.parameters.endDate).format('YYYY-MM-DD')
          : null,
      },
    })
  }

  private fetchData(resetPage?: boolean): void {
    if (this.isVoucherPaymentGroup && this.isGroupedByCustomer) {
      this.fetchDistributions(resetPage)
    } else {
      this.fetchHistory(resetPage)
    }
  }

  private fetchHistory(resetPage?: boolean): void {
    if (resetPage) {
      this.parameters.page = 1
    }

    const noDateParams = {
      programmaticVoucherItemId: this.params.programmaticVoucherItemId,
      page: this.params.page,
      perPage: this.params.perPage,
      sortBy: this.params.sortBy
    }

    const params =
      this.isVoucherPaymentGroup && this.isGroupedByCustomer
        ? noDateParams
        : this.params

    if (this.isEmptyPurposePointAndExpire) {
      controller.getVoucherHistoryV2({
        id: this.$route.params.id,
        params,
      })
    } else {
      controller.getVoucherHistory({
        id: this.$route.params.id,
        params,
      })
    }
  }

  private fetchDistributions(resetPage?: boolean): void {
    if (resetPage) {
      this.parameters.page = 1
    }

    controller.getVoucherDistributions({
      id: this.$route.params.id,
      params: {
        programmaticVoucherItemId: this.params.programmaticVoucherItemId,
        page: this.params.page,
        perPage: this.params.perPage,
        sortBy: this.params.sortBy
      },
    })
  }

  private getExportUrl(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {page, perPage, ...params} = this.params
    if (this.isEmptyPurposePointAndExpire) {
      controller.getExportedFileV2({
        id: this.$route.params.id,
        params: params,
      })
    } else {
      controller.getExportedFile({
        id: this.$route.params.id,
        params: params,
      })
    }
  }

  get groupPaymentCustomer(): string | undefined {
    return this.isVoucherPaymentGroup &&
    this.$route.query.programmaticVoucherItemId &&
    this.customerName
      ? ` - ${this.customerName}`
      : undefined
  }

  beforeDestroy(): void {
    controller.resetVoucherHistory()
    EventBus.$off(EventBusConstants.DOWNLOAD_VOUCHER_SUCCESS)
  }
}
